import "./styles.css";

import CTAForm from "../../components/CTAForm";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">Bem-vindo(a) ao</h1>

          <h2 className="title_middle">
            <span>escritório de advocacia</span>
            especializado em direito da família
          </h2>

          <p className="text">
            Entendemos que questões relacionadas ao direito da família podem ser
            difíceis e emocionalmente desgastantes, e estamos aqui para
            ajudá-lo(a) a navegar por essas situações com confiança e
            tranquilidade.
          </p>

          <CTAForm />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">O ESCRITÓRIO</h2>

          <p className="text">
            Nosso escritório se dedica a fornecer assistência jurídica eficaz e
            personalizada para clientes que precisam de ajuda em assuntos de
            separação, divórcio, guarda de crianças e pensão alimentícia. Nossa
            equipe de advogados altamente qualificados tem anos de experiência
            em lidar com essas questões e está pronta para ajudá-lo(a) a
            alcançar a melhor solução para o seu caso.
          </p>

          <h2 className="title">NOSSOS SERVIÇOS</h2>

          <div className="gallery">
            <div className="item">
              <h2 className="title">divórcio</h2>

              <p className="text">
                Quando se trata de questões de divórcio, sabemos que cada caso é
                único e exige soluções personalizadas. Nossa equipe trabalha com
                você(a) para entender suas necessidades individuais e
                ajudá-lo(a) a obter os melhores resultados para sua situação.
                Acreditamos que a comunicação é fundamental para uma parceria
                bem-sucedida com nossos clientes e estamos sempre disponíveis
                para responder a quaisquer perguntas ou preocupações que você
                possa ter ao longo do processo.
              </p>
            </div>

            <div className="item">
              <h2 className="title">guarda de crianças</h2>

              <p className="text">
                Em assuntos de guarda de crianças, nosso objetivo é proteger o
                melhor interesse da criança e encontrar uma solução justa para
                todas as partes envolvidas. Nossa equipe de advogados está
                altamente qualificada para lidar com casos de guarda e
                trabalhará com você(a) para encontrar a melhor solução possível
                para sua situação específica. Estamos dedicados a garantir que
                as crianças envolvidas sejam mantidas em segurança e que a
                melhor solução seja encontrada.
              </p>
            </div>

            <div className="item">
              <h2 className="title">pensão alimentícia</h2>

              <p className="text">
                A pensão alimentícia pode ser um tópico difícil e muitas vezes
                controverso em casos de divórcio. É importante ter um advogado
                experiente ao seu lado para ajudá-lo(a) a garantir que suas
                necessidades e os de seus filhos sejam atendidos adequadamente.
                Nossa equipe de advogados está pronta para ajudá-lo(a) a
                determinar uma solução justa e adequada para sua situação
                específica.
              </p>
            </div>
          </div>

          <CTAForm />

          <p className="bottom">
            Na advocacia de direito da família, estamos empenhados em fornecer
            serviços personalizados e atendimento individualizado a cada um de
            nossos clientes. Acreditamos que a melhor solução é alcançada
            através da colaboração e da construção de uma parceria forte e
            positiva com nossos clientes.
          </p>
        </article>
      </section>
    </header>
  );
};

export default Header;
