import "./styles.css";

import CTAForm from "../../components/CTAForm";

import about1 from "../../assets/about1.png";
import bullet from "../../assets/bullet.png";

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <figure className="content">
          <img src={about1} alt="About" />
        </figure>

        <p className="text1">
          Se você precisa de ajuda em questões de separação, divórcio, guarda de
          crianças ou pensão alimentícia, entre em contato conosco para agendar
          uma consulta gratuita e confidencial. Deixe-nos ajudá-lo(a) a
          encontrar a melhor solução para sua situação familiar.
        </p>

        <h2 className="title">ÁREAS DE ATUAÇÃO</h2>

        <p className="text2">
          Atuamos nas áreas de Direito de Família e Sucessões, Direito Cível e
          Direito Criminal:
        </p>

        <div className="gallery">
          <div className="left">
            <h2 className="item_title">Família e Sucessões</h2>

            <ul>
              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Divórcio Judicial, Consensual e Litigioso;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Reconhecimento e Dissolução de União Estável;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Partilha de Bens;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Ação de Alimentos;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Execução de Alimentos;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Guarda e Regulamentação de Visitas;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Alienação Parental;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Investigação de Paternidade;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Alvarás e Adjudicações;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Interdição Judicial;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Inventário e Arrolamento;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Testamento;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Planejamento Sucessório;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Pacto Antenupcial;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Contrato de Convivência e Contrato de Namoro;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Adoção;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Direito Homoafetivo e Diversidade Sexual;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Biodireito;</p>
              </li>

              <li>
                <figure>
                  <img src={bullet} alt="Bullet Point" />
                </figure>

                <p>Doação de Bens.</p>
              </li>
            </ul>
          </div>

          <div className="right">
            <div className="top">
              <h2 className="item_title">Direito Cível</h2>

              <ul>
                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Contratos: Elaboração e Execução;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Execuções de títulos judiciais e extrajudiciais;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Indenizações</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Medidas Cautelares;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Obrigações em Geral;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Responsabilidade Civil;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Temas atinentes a locação;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Cobrança;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Contencioso e Consultivo.</p>
                </li>
              </ul>
            </div>

            <div className="bottom">
              <h2 className="item_title">Direito Penal</h2>

              <ul>
                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Atendimentos em flagrantes;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Acompanhamento em audiência e oitivas;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Inquérito e Processo Criminal:</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Habeas Corpus;</p>
                </li>

                <li>
                  <figure>
                    <img src={bullet} alt="Bullet Point" />
                  </figure>

                  <p>Defesa e Recursos Criminais.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <CTAForm />
      </div>
    </section>
  );
};

export default About;
