import React from "react";
import "./styles.css";

import logo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure>
          <img src={logo} alt="Logo" />
        </figure>
      </section>
    </nav>
  );
};

export default Menu;
